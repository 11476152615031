let resumeData = {
    "imagebaseurl":"https://rbhatia46.github.io/",
    "name": "Marina Plopeanu",
    "role": "Software Engineer, Full Stack",
    "linkedinId":"Your LinkedIn Id",
    "skypeid": "Your skypeid",
    "roleDescription": "I like dabbling in various parts of frontend development and like to learn about new technologies, write technical articles or simply play games in my free time.",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/marina-plopeanu-59aa308b/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"https://github.com/marinaplopeanu",
          "className":"fa fa-github"
        },
        {
          "name":"envelope",
          "url":"mailto:plopeanu.marina@gmail.com",
          "className":"fa fa-envelope"
        }
        
      ],
    "aboutme":"Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.",
    "address":"Boston Area, USA",
    "website":"google.com",
    "education":[
      {
        "UniversityName":"Thinkful",
        "specialization":"Software Engineering, Full Stack",
        // "MonthOfPassing":"Aug",
        "YearOfPassing":"2018",
        "Achievements":"Some Achievements"
      },
      {
        "UniversityName":"Plymouth State University",
        "specialization":"Bachelor of Arts in Sociology, Minor in General Mathematics",
        // "MonthOfPassing":"Jan",
        "YearOfPassing":"2016",
        "Achievements":"Some Achievements"
      }
    ],
    "work":[
      {
        "CompanyName":"F.W. Webb Company",
        "specialization":"Web Developer",
        "MonthOfStarting":"Apr",
        "YearOfStarting":"2019",
        // "MonthOfLeaving":"Jan",
        "YearOfLeaving":"Present",
        "Achievements":"Some Achievements"
      },
      {
        // "CompanyName":"F.W. Webb Company",
        "specialization":"Jr. Web Developer (Part-time)",
        "MonthOfStarting":"Oct",
        "YearOfStarting":"2018",
        "MonthOfLeaving":"Aug",
        "YearOfLeaving":"2019",
        "Achievements":"Some Achievements"      
      },
      {
        
        // "CompanyName":"F.W. Webb Company",
        "specialization":"Data Management Specialist",
        "MonthOfStarting":"Apr",
        "YearOfStarting":"2018",
        "MonthOfLeaving":"Mar",
        "YearOfLeaving":"2019",
        "Achievements":"Some Achievements"    

      },
      {
        "CompanyName":"451 Research",
        "specialization":"Data Research Associate, Voice of the Enterprise",
        "MonthOfStarting":"Jul",
        "YearOfStarting":"2016",
        "MonthOfLeaving":"Feb",
        "YearOfLeaving":"2018",
        "Achievements":"Some Achievements"    
      },
      {
        "CompanyName":"Appalachian Mountain Club",
        "specialization":"Data Intern",
        "MonthOfStarting":"Oct",
        "YearOfStarting":"2015",
        "MonthOfLeaving":"Mar",
        "YearOfLeaving":"2016",
        "Achievements":"Some Achievements"          
      },

    ],
    "skillsDescription":"Your skills here",
    "skills":[
      {
        "skillname":"HTML5"
      },
      {
        "skillname":"CSS"
      },
      {
        "skillname":"ReactJS"
      },
      {
        "skillname":"NodeJS"
      },
      {
        "skillname":"PHP"
      },
      {
        "skillname":"MySQL"
      }
    ],
    "portfolio":[
      {
        "name":"project1",
        "description":"mobileapp",
        "imgurl":"images/portfolio/phone.jpg"
      },
      {
        "name":"project2",
        "description":"mobileapp",
        "imgurl":"images/portfolio/project.jpg"
      },
      {
        "name":"project3",
        "description":"mobileapp",  
        "imgurl":"images/portfolio/project2.png"
      },
      {
        "name":"project4",
        "description":"mobileapp",
        "imgurl":"images/portfolio/phone.jpg"
      }
    ],
    "testimonials":[
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      },
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      }
    ]
  }
  
  export default resumeData



